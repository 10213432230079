@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;900&display=swap');
@import 'shared/styles/palette';

.dynamic-pdf {
    min-height: 100vh;
    background: $background;

    ::-webkit-scrollbar {
        width: 5px;
    }
      
    ::-webkit-scrollbar-track {
        background: #f1f1f1;

    }
      
    ::-webkit-scrollbar-thumb {
        background: rgba($text, .3);
        border-radius: 5px;
    }
      
    ::-webkit-scrollbar-thumb:hover {
        background: rgba($text, .5);
    }
}

.container {
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
}

.wide-container {
    display: flex;
    max-width: 1800px;
    margin: 0 auto;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
}

.content {
    margin: 0 auto 30px;
    background: $white;
    box-shadow: 0px 9px 40px rgb($light-blue, .05), 0px 1.8px 6.5px rgb($light-blue, .03);
    border-radius: 4px;
}

h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

a {
    color: $link;
}

sub, sup {
    font-size: xx-small;
}

ol ul, ul ul {
    list-style-type: circle;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes moveFromRight {
    0% {
        transform: translateX(1200px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(-27rem) scale(0.9);
        opacity: 0;
    }

    100% {
        transform: translateX(0rem) scale(1);
        opacity: 1;
    }
}

@keyframes moveLeft {
    0% {
        transform: translateX(0rem) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(27rem) scale(0.9);
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
