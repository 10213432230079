@import 'shared/styles/palette';

button.dpdf-button {
    box-shadow: none;
    font-size: 14px;
    text-transform: none;

    &_text-transform {
        text-transform: uppercase;
    }
}
