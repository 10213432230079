@import 'shared/styles/palette';

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;

    font-weight: normal;
    color: $text;
}
