@import 'shared/styles/palette';

div.dpdf-modal {
    &-paper {
        padding: 50px;
        max-width: none !important;
        gap: 25px !important;

        @media screen and (max-width: 730px) {
            padding: 20px;
        }
    }
}
