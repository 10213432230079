@import 'shared/styles/palette';

.feedbackContainer {
    position: fixed;
    right: -35px;
    top: 50%;

    @media screen and (max-width: 550px) {
        top: 85%;
    }
}

.feedbackButton {
    transform: rotateZ(-90deg);
}
