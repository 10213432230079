@import 'shared/styles/palette';

.page-error {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: 300px;
    flex-direction: column;

    &__text {
        font-size: 24px;
        margin-bottom: 20px;
    }
}
