@import 'shared/styles/palette';

.dpdf-dialog {
    &__paper {
        padding: 50px;
        max-width: none !important;
    }

    &__root {
        padding: 0 !important;
    }

    &__actions {
        padding: 28px 0 0 !important;
    }

    &__proceed-button {
        margin-right: 5px;
    }
}
