.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon {
        font-size: 10em !important;
    }

    &__icon,
    &__text {
        color: red;
    }

    &__text {
        font-size: 3em;
    }
}
