@import 'shared/styles/palette';

.image {
    display: block;
    border-radius: 1px;

    &_preview {
        margin: 13px 0;
    }

    &_center {
        margin-left: auto;
        margin-right: auto;
    }

    &_right {
        margin-left: auto;
    }
}
