$black: #000000;
$white: #ffffff;

$grey: #9FA1A5;
$grey-transparent: rgba($grey, .15);

$grey-1: #333333;
$grey-2: #9fa1a50d;
$light-grey: #E6E8ED;

$light-grey-2: #f4f9fb;
$light-grey-3: #fbf5f5;
$light-grey-4: #f5f5f5;
$light-grey-5: #D9D9D9;
$grey-black: #292D32;
$grey-shadow: 0px 0px 8px rgb(204 208 216 / 70%);

$text: #323232;

$error: #d32f2f;

$red: #F85454;
$light-red: #FFF0F0;

$background: #F4F6FA;
$block-background: rgba($grey, .05);

$link: #0563c1;

$blue: #0277BD;
$light-blue: #8a92b5;
$light-blue-1: #E6F2F9;

$orange: #ed833a;
$light-orange: #FFAD66;
$light-yellow: #F3D08E;
$light-yellow-1: #fffefa;

$green: #80C6AB;
$light-green: #E2EFE9;
