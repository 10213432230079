.not-found {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    flex-direction: 'column';
    border-radius: '20px';
    max-width: '900px';
    margin: '0 auto';

    &__title {
        margin: '3px 0';
        font-weight: 'bold';
    }

    &__link-container {
        margin: '10px 0';
    }

    &__link {
        text-decoration: 'none';
        display: 'flex';
        align-items: 'center';
        padding: '5px';
        border-radius: '4px';
        cursor: 'ponter';
        margin-right: '10px';

        &:last-child {
            margin-right: 0;
        };
    }
}
