@import 'shared/styles/palette';

.feedback {
    display: flex;
    flex-direction: column;

    &__question {
        font-size: 20px;
    }

    &__icons {
        margin: 10px 0;
    }
     
    &__button {
        border: 1px solid $orange !important;
        color: $orange !important;
        width: 40px !important;
        height: 40px !important;
        margin: auto !important;
    }

    &__input {
        margin-bottom: 20px !important;
    }
}
