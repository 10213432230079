@import 'shared/styles/palette';

.popper {
    background-color: $white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, .23);
    width: 100%;
    max-width: 400px;
    z-index: 100;

    display: flex;
    flex-direction: column;

    &__close-button {
        margin: -15px -15px 0 auto !important;
        z-index: 1;
    }

    &__arrow,
    &__arrow::before {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: transparent;
        left: -6px;
    }

    &__arrow::before {
        content: "";
        transform: rotate(45deg);
        background-color: $white;
    }
    
    &[data-popper-placement^="top"] {
        margin-bottom: 9px !important;
    }

    &[data-popper-placement^="bottom"] {
        margin-top: 4px !important;
    }

    &[data-popper-placement^="left"] {
        margin-right: 4px !important;
    }

    &[data-popper-placement^="right"] {
        margin-left: 4px !important;
    }

    &[data-popper-placement^="top"] .popper__arrow,
    &[data-popper-placement^="top"] .popper__arrow::before  {
        bottom: -3px;
    }

    &[data-popper-placement^="bottom"] .popper__arrow,
    &[data-popper-placement^="bottom"] .popper__arrow::before {
        top: -3px;
    }
      
    &[data-popper-placement^="left"] .popper__arrow,
    &[data-popper-placement^="left"] .popper__arrow::before {
        right: -3px;
    }
      
    &[data-popper-placement^="right"] .popper__arrow,
    &[data-popper-placement^="right"] .popper__arrow::before {
        left: -3px;
    }
      
    &[data-popper-reference-hidden] .popper__arrow::before {
        visibility: hidden;
    }

    @media screen and (max-width: 650px) {
        width: 85%;
    }
}
